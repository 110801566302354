import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

export const propTypes = PropTypes.SitecoreItem.inject({
  //GraphQL additionalData is too nested for proptypes to be defined
  additionalData: PropTypes.shape({
    data: PropTypes.shape({
      item: PropTypes.shape({
        children: PropTypes.arrayOf({
          ...PropTypes.GraphQL.basePage,
          template: PropTypes.shape({
            name: PropTypes.string,
          }),
          contentTags: PropTypes.shape({
            targetItems: PropTypes.arrayOf(
              PropTypes.shape({
                field: PropTypes.shape({
                  value: PropTypes.string,
                }),
              }),
            ),
          }),
          hideFromListing: PropTypes.shape({
            boolValue: PropTypes.boolean,
          }),
          children: PropTypes.arrayOf({
            ...PropTypes.GraphQL.basePage,
            template: PropTypes.shape({
              name: PropTypes.string,
            }),
            contentTags: PropTypes.shape({
              targetItems: PropTypes.arrayOf(
                PropTypes.shape({
                  field: PropTypes.shape({
                    value: PropTypes.string,
                  }),
                }),
              ),
            }),
            hideFromListing: PropTypes.shape({
              boolValue: PropTypes.boolean,
            }),
          }),
        }),
      }),
    }),
  }),
  defaultTitle: PropTypes.SingleLineText,
  filterButton: PropTypes.SingleLineText,
  noResults: PropTypes.SingleLineText,
  watchText: PropTypes.SingleLineText,
  downloadText: PropTypes.SingleLineText,
  readText: PropTypes.SingleLineText,
  filterDropdowns: PropTypes.Multilist.inject({
    label: PropTypes.SingleLineText,
    filterOnLoad: PropTypes.Multilist.inject({
      value: PropTypes.SingleLineText,
      label: PropTypes.SingleLineText,
    }),
  }),
  hasCategories: DefaultProps.Checkbox,
  filterOnLoad: DefaultProps.Checkbox,
  rootItem: DefaultProps.BasePage,
});

export const defaultProps = DefaultProps.SitecoreItem.inject({
  additionalData: {},
  defaultTitle: DefaultProps.SingleLineText,
  filterButton: DefaultProps.SingleLineText,
  noResults: DefaultProps.SingleLineText,
  watchText: DefaultProps.SingleLineText,
  downloadText: DefaultProps.SingleLineText,
  readText: DefaultProps.SingleLineText,
  filterDropdowns: DefaultProps.Multilist,
  hasCategories: DefaultProps.Checkbox,
  filterOnLoad: DefaultProps.Checkbox,
  rootItem: DefaultProps.SitecoreItem.inject({
    Title: DefaultProps.SingleLineText,
  }),
});
