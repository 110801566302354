import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { propTypes, defaultProps } from './Listing.props';

class Listing extends React.Component {
  constructor(props) {
    super(props);
    const {
      additionalData,
      filterDropdowns,
      hasCategories,
      filterOnLoad,
      itemsPerGroup,
      isOneColumn,
    } = this.props.fields;

    if (additionalData && additionalData.data) {
      const mappedFilters = this.mapFilters(filterDropdowns);
      const mappedContent = this.mapContent(
        additionalData.data.item,
        hasCategories.value,
      );
      this.state = {
        categories: mappedContent.categories,
        content: mappedContent.content,
        filteredContent: hasCategories.value ? {} : [],
        activeFilters: filterOnLoad.value
          ? [mappedFilters[0].list[0].value]
          : [],
        filters: mappedFilters,
        resultsCounter:
          isOneColumn.value && !!parseInt(itemsPerGroup.value)
            ? parseInt(itemsPerGroup.value)
            : null,
      };
    } else {
      this.state = {
        content: null,
        filters: [],
        resultCounter: null,
      };
    }
  }

  componentDidMount() {
    this.setState((prevState) => {
      return {
        filteredContent: this.props.fields.filterOnLoad.value
          ? this.filterContent()
          : prevState.content,
      };
    });
  }

  mapFilters = (filterDropdowns) => {
    return filterDropdowns.map(({ fields }) => {
      return {
        label: fields.label.value,
        ref: React.createRef(),
        list: fields.filterOptions.map((option) => {
          return {
            label: option.fields.Title.value,
            value: option.fields.Title.value,
          };
        }),
      };
    });
  };

  mapContent = (item, hasCategories) => {
    let categories = null;
    let content = [];

    item.children.forEach((child) => {
      content.push(child, ...child.children);
    });
    content = content.filter((items) => !items.hideFromListing.boolValue);

    if (hasCategories) {
      categories = [];
      const categorisedContent = {};
      item.children.forEach((child) => {
        const category = child.title.value.replace(/\s/g, '');
        categories.push({
          key: category,
          title: child.title.value,
        });
        categorisedContent[category] = child.children.filter(
          (child) => !child.hideFromListing.boolValue,
        );
      });
      content = categorisedContent;
    }

    return {
      content: content,
      categories: categories,
    };
  };

  filterContent = () => {
    const { categories, content, activeFilters } = this.state;
    if (!activeFilters.length) {
      return content;
    }
    let filteredContent;
    let hasResults = false;

    const filter = (contentItem) => {
      let remainingFilters = activeFilters;
      contentItem.contentTags.targetItems.forEach((tag) => {
        if (remainingFilters.includes(tag.field ? tag.field.value : null)) {
          remainingFilters = remainingFilters.filter(
            (filter) => filter !== tag.field.value,
          );
        }
      });
      return !remainingFilters.length;
    };

    if (!!categories) {
      filteredContent = {};
      categories.forEach(({ key }) => {
        const newContent = content[key].filter((contentItem) =>
          filter(contentItem),
        );
        if (!!newContent.length) {
          filteredContent[key] = newContent;
          hasResults = true;
        }
      });
    } else {
      filteredContent = content.filter((contentItem) => filter(contentItem));
      hasResults = !!filteredContent.length;
    }

    return hasResults ? filteredContent : null;
  };

  filter = (e) => {
    e.preventDefault();
    this.setState(
      (prevState) => {
        return {
          activeFilters: prevState.filters
            .map((filter) => filter.ref.current.value)
            .filter((value) => value !== 'all'),
        };
      },
      () => {
        this.setState({ filteredContent: this.filterContent() });
      },
    );
  };

  renderCategorisedContent = () => {
    const { categories, filteredContent } = this.state;
    return categories.map(({ key, title }) => {
      if (!filteredContent[key] || !filteredContent[key].length) {
        return null;
      }
      return (
        <React.Fragment key={key}>
          <h2 className="category-heading">{title}</h2>
          {this.renderContent(filteredContent[key])}
        </React.Fragment>
      );
    });
  };

  renderContent = (contentArray) => {
    const { fields } = this.props;
    const { resultsCounter } = this.state;

    const buttonText = (item) => {
      if (item.template.name === 'video-page') {
        return fields.watchText.value;
      } else if (item.template.name === 'download-page') {
        return fields.downloadText.value;
      }
      return fields.readText.value;
    };

    let additionalClass;
    if (fields.isOneColumn.value) {
      additionalClass = 'single-column';
    } else if (fields.rowOfFour.value) {
      additionalClass = 'has-4-items';
    }

    return (
      <div className={`listing-content-container ${additionalClass}`}>
        {contentArray.map((item, index) => {
          let buttonText;
          switch (item.template.name) {
            case 'video-page':
              buttonText = fields.watchText.value;
              break;
            case 'download-page':
              buttonText = fields.downloadText.value;
              break;
            case 'listing-page':
              buttonText = null;
              break;
            default:
              buttonText = fields.readText.value;
          }
          const title = item.navigationTitle.value || item.title.value;
          const link = item.navigationLink.url || item.url;
          return !resultsCounter || index < resultsCounter ? (
            <a
              href={link}
              className="content-card clearfix"
              title={title}
              key={item.id}
            >
              {item.image.jss.value.src && (
                <div
                  className={`img-container ${
                    item.template.name === 'video-page'
                      ? 'is-video-article'
                      : ''
                  }`}
                >
                  <img
                    alt={item.image.jss.value.alt}
                    src={item.image.jss.value.src}
                  />
                </div>
              )}
              <div className="content-container">
                <div className="content-container__grouper">
                  <h3 className="title">{title}</h3>
                  {!!item.subtitle && (
                    <p className="subtitle">{item.subtitle.value}</p>
                  )}
                  <p className="description">{item.description.value}</p>
                  {buttonText && (
                    <span className="cta-button">{buttonText}</span>
                  )}
                </div>
              </div>
            </a>
          ) : null;
        })}

        {!!resultsCounter && resultsCounter < contentArray.length && (
          <button
            type="button"
            className="cta-button secondary m-full-width"
            onClick={() =>
              this.setState((prevState) => {
                return {
                  resultsCounter:
                    prevState.resultsCounter +
                    parseInt(fields.itemsPerGroup.value),
                };
              })
            }
          >
            <Text field={fields.viewMoreText} />
          </button>
        )}
      </div>
    );
  };

  render() {
    const { fields } = this.props;
    const { filters, categories, filteredContent, activeFilters } = this.state;

    const contentItems = !!filteredContent ? (
      categories ? (
        this.renderCategorisedContent()
      ) : (
        <React.Fragment>
          <h2 className="category-heading">
            {activeFilters[0] || fields.defaultTitle.value}
          </h2>
          {this.renderContent(filteredContent)}
        </React.Fragment>
      )
    ) : null;

    return (
      <div
        className={`listing ${
          fields.floatLeft.value ? 'listing-filter-on-left' : ''
        } l-padding`}
      >
        {!!filters.length && (
          <div className={fields.floatLeft.value ? 'filter-on-left' : ''}>
            <Text field={fields.filterTitle} tag="h4" />
            <input
              aria-disabled="true"
              id="listing-dropdown-trigger"
              className="dropdown-trigger-check"
              type="checkbox"
            />
            <label
              aria-disabled="true"
              className="dropdown-trigger"
              htmlFor="listing-dropdown-trigger"
            >
              {fields.ariaLabel.value}
            </label>
            <form className="listing-filter" onSubmit={this.filter}>
              {filters.map((filter, index) => (
                <span key={filter.id} className="listing-filter-col">
                  <label htmlFor={`listing-filter-${index}`}>
                    {filter.label}
                  </label>
                  <select
                    ref={filter.ref}
                    id={`listing-filter-${index}`}
                    onChange={(e) => {
                      if (filters.length === 1) {
                        this.filter(e);
                      }
                    }}
                    className="select dropdown"
                  >
                    {!fields.filterOnLoad.value && (
                      <option value="all">
                        {fields.dropdownDefault.value}
                      </option>
                    )}
                    {filter.list.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </span>
              ))}
              {filters.length > 1 && (
                <span className="listing-filter-col">
                  <button
                    type="submit"
                    className="filter-button cta-button tertiary"
                  >
                    {fields.filterButton.value}
                  </button>
                </span>
              )}
            </form>
          </div>
        )}
        {contentItems || (
          <div className="no-results-found">
            <p>{fields.noResults.value}</p>
          </div>
        )}
      </div>
    );
  }
}

Listing.propTypes = propTypes;
Listing.defaultProps = defaultProps;

export default Listing;
